@import url('https://fonts.googleapis.com/css2?family=Lilita+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poiret+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Voltaire&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Electrolize&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Russo+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Stardos+Stencil:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Arvo:wght@700&display=swap');


/* .services {
    background: khaki;
    position: relative;
    bottom: 215px;
  } */
  .services {
    background: url('/public/images/serv.jpeg') no-repeat center center;
    background-size: cover;
    /* background: white; */
    position: relative;
    bottom: 196px;
    margin-top: -100px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: bolder;  
    
  }
 
  .services:before {
    content: "";
    display: block;
    position: absolute;
    top: -10px;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, .7);
    z-index: 0;
    margin: 10px;
    margin-left: -1px;
  }
  
  .services-container {
    position: relative;
    top: -5px;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
    padding-bottom: 40px;
    /* padding-bottom: 0px; */
  }
 
  
  .col-md-4:hover {
    transform: scale(1.05);
    transition: transform 0.3s ease-in-out;
  }
  
  @media (max-width: 767px) {
    .services-container{
      
      width: 80% !important;
    }
  }
  /* @media (max-width: 500px) {
    .services-container{
      height: 1400px;
    }
  } */
  /* @media (max-width: 400px) {
    .services-container{
      height: 1300px;
    }
  }
  @media (max-width: 300px) {
    .services-container{
      height: 1200px;
    }
  } */
  .service-description {
    margin-top: 1rem;
    text-align:left;
    text-justify: inter-word;
    font-size: 24px;
    font-weight: bold;
    /* font-family: 'Plus Jakarta Sans', sans-serif; */
    font-family: 'Plus Jakarta Sans', sans-serif;
  }
  .services-title {
    font-weight: bolder;
    /* text-decoration: underline; */
    text-align: center;
    margin: 2rem 0;
    font-size: 190px;
    padding-top: 30px !important;
    /* font-family: 'Russo One', sans-serif; */
    font-family: 'Electrolize', sans-serif;
    text-shadow: -2px -2px 0 #ec5d24, 2px -2px 0 #ec5d24, -2px 2px 0 #ec5d24, 2px 2px 0 #ec5d24;

  }
  .service-button {
    
    background-color: #ec5d24 !important;
    color: black;
    padding: 5px;
    font-size: 36px !important;
    font-weight: bolder !important;
    line-height: 1.5;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    width: 100%;
    z-index: 20;
    border: none !important;
    /* font-family: 'Voltaire', sans-serif; */
    font-family: 'Plus Jakarta Sans', sans-serif;
    border-radius: 40px;
    white-space: nowrap;
    margin-bottom: 20px;
  }
  .services .service-button:hover {
    background-color: black !important;
    color: #ec5d24 !important;
    border: none !important;
  }
  @media (max-width: 436px) {
    .services-title{
      font-size: 45px !important;
    }
  }
  @media (max-width: 364px) {
    .services-title{
      font-size: 35px !important;
    }
  }
  
  .service-name {
    text-align: center;
    font-size: 60px;
    white-space: nowrap;
    font-weight: bolder !important;
    /* text-shadow: 0 0 2px #000, 0 0 0 #000, 0 0 2px #000; */
    
    /* font-family: 'Plus Jakarta Sans', sans-serif; */
    /* font-family: 'Electrolize', sans-serif; */
    font-family: 'Arvo', serif;
    /* text-shadow: -2px -2px 0 black, 2px -2px 0 black, -2px 2px 0 black, 2px 2px 0 #ec5d24; */
    /* font-family: 'Stardos Stencil', cursive; */

  }

  @media(max-width:1399px){
    .service-name{
      font-size: 50px;
    }
    .service-description{
      font-size: 22px;
    }
    .services-title{
      font-size: 160px;
    }
  }
  @media(max-width:1199px){
    .service-name{
      font-size: 42px;
    }
    .service-description{
      font-size: 19px;
    }
    .services-title{
      font-size: 130px;
    }
  }
  @media(max-width:991px){
    .service-name{
      font-size: 30px;
    }
    .service-description{
      font-size: 13px;
    }
    .services-title{
      font-size: 100px;
    }
    .service-button{
      font-size: 28px !important;
    }

  }
  @media(max-width: 767px) {
    .services-title{
      font-size: 9vw;
    }
    .service-name{
      font-size: 8vw;
    }
    .service-description{
      font-size: 18px;
    }
    .service-button{
      margin-bottom: 30px;
    }
  }
  @media(max-width:285px) {
    .services-title{
      font-size: 30px !important;
    }
  }
  .service-img {
    display: block;
    margin: 0 auto;
    width: 100%;
    border-radius: 10px;
    border: 5px solid #ec5d24;
  }
  
  
 
  
  @media(max-width:500px){
    .service-button{
      font-size: 22px !important;
    }
  }
  .service-enter {
    opacity: 0;
    transform: translateY(-50%);
  }
  
  .service-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: all 1s ease-in-out;
  }
  
  .service-exit {
    opacity: 1;
    transform: translateY(0);
  }
  
  .service-exit-active {
    opacity: 0;
    transform: translateY(-50%);
    transition: all 1s ease-in-out;
  }
  
  @media(min-width:2651px){
    .services-title{
      font-size: 212.08px;
    }
  }