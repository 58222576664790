@import url('https://fonts.googleapis.com/css2?family=Lilita+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poiret+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Voltaire&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Passion+One&display=swap');

.navbar {
  flex: 1;
  justify-content: flex-end;
}
.navbar-toggler.active,
.navbar-toggler-icon.active, .navbar-toggler:focus {
  outline: none !important;
  box-shadow: none !important;
}

.navbar-collapse {
  align-items: flex-end;
}
.my-nav-dropdown {
    color: #ec5d24;
  }
  .dropdown>a::after {
    color: white;
}
.navbar-toggler-icon {
  outline:none;
}
.dropdown-menu.show{
  background-color: #ec5d24 !important;
}
.dropdown-item{
  font-size: 18px;
  font-weight: 900 !important;
  /* font-family: 'Plus Jakarta Sans', sans-serif; */
}
.dropdown-item:hover{
  background: black !important;
  color: #ec5d24 !important;
}
  .ms-auto{
    /* font-family: 'Lora', serif !important; */
    font-size: 30px;
    font-weight:900;
    /* transition: color 0.3s ease-out !important; */
    font-family: 'Plus Jakarta Sans', sans-serif;
    /* font-family: 'Anton', sans-serif; */
    /* font-family: 'Passion One', cursive; */
    /* margin-left:auto;
    padding-right: 20px; */
    /* display: flex !important;
  justify-content: flex-end !important; */
  }

  /* @media(min-width: 992px) {
    .ms-auto{
      position: fixed;
      right: 20px;
      top: 40px
    }
    
  } */
  /* @media(max-width: 991px) {
    .ms-auto{
      margin-top: 75px;
    
    }
  } */

 .menu{
  outline: none !important;
  
 }
  .nav-link, .my-nav-dropdown {
    color: white;
    /* transition: color 0.3s ease-out !important; */
  }
  .toDaRight{
    position: relative;
  }
  
  
  .nav-link:hover, .my-nav-dropdown:hover {
    color:#ec5d24 !important;
    }
  
  .nav-title{
    font-size: 24px !important;
  }
  .logo{
    /* style={{ height: '250px', width: '250px', marginLeft:"0 !important", marginTop: -40, marginBottom: -40 }} */
    height: 200px;
    width: 200px;
    margin-left: 0 !important;
    margin-top: -50px;
    margin-bottom: -60px;
   
  }
  @media(min-width:992px){
   .toDaRight{
    position: absolute;
    right: 50px;
   }
   .logo{
    position: absolute;
    left: 0;
    top:15px;
   }
  }
  @media(max-width:444px){
    .logo{
      height: 150px;
      width: 150px;
      margin-top: -30px;
    }
  }