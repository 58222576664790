@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Electrolize&display=swap');



.contact-btn {
    background-color: #ec5d24 !important;
    color: black;
    padding: 10px;
    font-size: 16px;
    line-height: 1.5;
    cursor: pointer;
    transition: background-color 0.4s ease-in-out;
    width: 100%;
    z-index: 20;
    border: none !important;
  }
  .contact-btn:hover {
    background-color: black !important;
    color: #ec5d24 !important;
    border: none !important;
  }
  .contact-container{
    font-family: 'Plus Jakarta Sans', sans-serif;



  }
  .contact-subtitle{
    font-size: 20px;
  }
  .contact-title{
    font-family: 'Electrolize', sans-serif;
  text-shadow: -2px -2px 0 #ec5d24, 2px -2px 0 #ec5d24, -2px 2px 0 #ec5d24, 2px 2px 0 #ec5d24;
  font-size:100px;
  margin-top: -30px;
  margin-bottom: 50px;
  white-space: nowrap;
  }
  @media(min-width:1400px){
    .contact-title{
      font-size: 120px;
    }
  }
  .iconButt{
    background:#ec5d24; 
    border-radius:100px; 
    width:20%;
    transition: background 0.4s ease-in-out;
  }
  @media(max-width:1399px){
    .iconButt{
      width: 23%;
    }
    .contact-subtitle{
      font-size: 18px;
    }
  }
  @media(max-width:1199px){
    .iconButt{
      width: 28%;
    }
    .contact-subtitle{
      font-size: 15px;
    }
    .btbg{
      min-height: 165px;
      margin-bottom: 50px;
    }
    .contact-title{
      font-size: 80px;
    }
  }
  @media(max-width:991px){
    .contact-title{
      font-size: 60px;
      margin-bottom: 50px;
    }
    .iconButt{
      width: 11%
    }
    .contact-subtitle{
      font-size: 30px;
    }
    /* .btbg{
      min-height: 300px;
    } */
  }
  @media(max-width: 768px){
    .iconButt{
      width: 15%;
    }
  }
  @media(max-width: 515px){
    .contact-title{
      font-size: 10.5vw;
    }
    .iconButt{
      width: 70px;
    }
    .contact-subtitle{
      font-size: 20px;
    }
  }
  .iconButt{
    color: black;
  }
  .iconButt:hover{
    background: black;
  }
  .contact-icon:hover{
    color: #ec5d24;
  }
  .contact-link{
    color:black
  }
  .contact-link:hover{
    color: #ec5d24;
  }