@import url('https://fonts.googleapis.com/css2?family=Electrolize&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans&display=swap');

.sealcoat-title {
  font-family: 'Electrolize', sans-serif;
  text-shadow: -2px -2px 0 #ec5d24, 2px -2px 0 #ec5d24, -2px 2px 0 #ec5d24, 2px 2px 0 #ec5d24;
  font-weight: bold;
  font-size: 150px;
}

.sealcoat-subtitle {
  font-family: 'Electrolize', sans-serif;
  font-weight: bold;
  font-size: 60px;
}
@media(max-width:1399px) {
    .sealcoat-subtitle{
        font-size: 50px;
    }
}
@media(max-width:1199px) {
    .sealcoat-subtitle{
        font-size: 40px;
    }
    .sealcoat-title{
        font-size: 120px;
    }
}
@media(max-width:991px) {
    .sealcoat-subtitle{
        font-size: 30px;
    }
}
@media(max-width:767px) {
    .sealcoat-subtitle{
        font-size: 50px;
    }
    .sealcoat-title{
        font-size: 80px;
    }
}
@media(max-width:575px) {
    .sealcoat-subtitle{
        font-size: 11vw;
    }
    .sealcoat-title{
        font-size: 15vw;
    }
}

.sealcoat-container {
  font-family: 'Plus Jakarta Sans', sans-serif;
  background: #f5f5f5;
  margin-top: 100px;
}

.sealpage-text {
  margin-bottom: 20px;
  font-size: 22px;
}

.image-row .col-xs-4 {
  transition: all 0.3s;
}

.image-row .col-xs-4:hover {
  transform: scale(1.1);
}

.selected-image img {
  border: 5px solid #ec5d24;
}
@media(min-width:1400px){
.sealcontent{
  margin-left: 200px !important;
  margin-right: 200px !important;
}}
@media(min-width:992px) and (max-width: 1399px){
.sealcontent{
  margin-left: 100px !important;
  margin-right: 100px !important;
}
}