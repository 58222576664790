@import url('https://fonts.googleapis.com/css2?family=Lilita+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poiret+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Voltaire&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Electrolize&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans&display=swap');


/* Set the height of the hero section */
.slide-section {
    height: 600px;
    position: relative;
    bottom: 200px;
    overflow: hidden;
    padding-bottom: 200px;
  }
  
  /* Center the slideshow container vertically and horizontally */
  .slide-container {
    /* position: relative; */
    display: flex;
    align-items: center;
    justify-content: center;
    height: 600px;
    overflow: hidden;
    font-family: 'Plus Jakarta Sans', sans-serif;
    padding-bottom: 200px;
    /* min-width: 348px; */
  }
  
  /* Set the size of the images and center them */
  .slide img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-height: 600px;
    object-fit: cover;
  }
  
  /* Show only the active slide */
  .slide:not(.active) {
    display: none;
  }
  
  .free-quote {
    position: absolute;
    z-index: 1;
    top: 100%; /* updated value */
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 12px 24px;
    background-color: transparent;
    color: #ffffff;
    font-size: 3vw;
    font-weight: bold;
    border: 6px solid #ec5d24;
    border-radius: 50px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    margin-bottom: 50px; /* added margin-bottom */
    white-space: nowrap;
  }
  
  /* Set the styles for the book now button on hover */
  .free-quote:hover {
    background-color: #ec5d24;
    color: #000000;
  }
  /* Media query to ensure hero image takes up full width when screen is between 620px and 708px */
  /* @media (min-width: 620px) {
    .slideshow-container {
      min-width: 715px;
    }
  }
  @media (min-width: 940px) {
    .slideshow-container {
      min-width: 1092px;
    }
  }
  @media (min-width: 1260px) {
    .slideshow-container {
      min-width: 1450px;
      
    }
  } */
  
  /* Styles for hero title */
  .slide-title {
    font-size: 4.5vw;
    font-family: 'Plus Jakarta Sans', sans-serif;
    /* text-shadow: -2px -2px 0 #ec5d24, 2px -2px 0 #ec5d24, -2px 2px 0 #ec5d24, 2px 2px 0 #ec5d24; */

    font-weight: bold;
    color: #ffffff;
    /* text-align: center; */
    margin-bottom: 40px; /* updated value */
  }
  
  /* Styles for hero subtitle */
  .slide-subtitle {
    font-size: 2vw;
    font-weight: 900;
    color: #ffffff;
    text-align: center;
    margin-top: 100px; /* added margin-top */
    margin-bottom: 10px; /* added margin-bottom */
     white-space: nowrap;
     position: relative;
     bottom: 80px;

  }
  
  @media(max-width:600px) {
    .slide-subtitle{
      font-size: 1.5vw;
    }
  }
  /* Styles for hero content container */
  .slide-content {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 95%;
    /* font-family: 'Voltaire', sans-serif; */
    }
  
    @media(min-width:1800px){
      .slide-title{
        font-size: 81px;
      }
      .free-quote{
        font-size: 54px;
      }
      .slide-subtitle{
        font-size: 36px;
      }
    }