@import url('https://fonts.googleapis.com/css2?family=Boogaloo&family=Bungee+Shade&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lilita+One&display=swap');
@font-face {
  font-family: 'My Font';
  src: url('/public/my-font.ttf') format('truetype');
}
@import url('https://fonts.googleapis.com/css2?family=Poiret+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Voltaire&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Russo+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Graduate&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Stardos+Stencil:wght@700&display=swap');

/* Set the height of the hero section */
.hero-section {
    height: 100vh;
    position: relative;
    margin-bottom: 200px;
    overflow-x: hidden;
    min-height:600px;
  }

  
  
  /* Center the slideshow container vertically and horizontally */
  .slideshow-container {
    /* position: relative; */
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    overflow: hidden;
    width: 100%;
  }
  
  /* Set the size of the images and center them */
  .slide img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    min-height: 800px;
    object-fit: cover;
    z-index: 0;
  }
  
  
  /* Show only the active slide */
  .slide:not(.active) {
    display: none;
  }
  
  
  /* Set the styles for the book now button */
  .hero-button {
    /* font-family: 'Lilita One', cursive; */
    position: absolute;
    z-index: 1;
    top: 60%; /* updated value */
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 12px 24px;
    background-color: transparent;
    color: #ffffff;
    font-size:2vw;
    font-weight: bolder;
    border: 6px solid #ec5d24;
    border-radius: 50px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    margin-bottom: 50px; /* added margin-bottom */
    /* font-family: 'Voltaire', sans-serif; */
    font-family: 'Plus Jakarta Sans', sans-serif;
    }
  /* @media (max-width: 400px) {
    .hero-button{
      font-size: 80%;
    }
  } */
  

  /* Set the styles for the book now button on hover */
  .hero-button:hover {
    background-color: #ec5d24;
    color: #000000;
  }
  
  
  /* Styles for hero title */
  .hero-title {
    font-size: 6.5vw;
    font-weight: bold;
    color: #ffffff;
    text-align: center;
    margin-bottom: 200px; /* updated value */
  /* font-family: 'Boogaloo', cursive; */
  /* font-family: 'Bungee Shade', cursive; */
  /* font-family: 'Russo One', sans-serif; */
  font-family: 'Graduate', cursive;
  text-shadow: -2px -2px 0 #ec5d24, 2px -2px 0 #ec5d24, -2px 2px 0 #ec5d24, 2px 2px 0 #ec5d24;


  }
  
  
  /* Styles for hero subtitle */
  .hero-subtitle {
    font-size: 3vw;
    color: #ffffff;
    text-align: center;
    margin-top: 120px; /* added margin-top */
    margin-bottom: -10px; /* added margin-bottom */
    font-family: 'Stardos Stencil', cursive;
    font-weight:900 ;     }
  
  

  /* Styles for hero content container */
  .hero-content {
    position: absolute;
    z-index: 1;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    max-width: 80%;
    white-space: nowrap;
  }
  /* max sizes for hero content */
  @media (min-width:2095px){
    .hero-title{
      font-size: 150px;
    }
    .hero-subtitle{
      font-size: 80px;
    }
    .hero-button{
      font-size: 61.9px;
    }
  }
  @media (max-width: 991px) {
    .hero-title{
      font-size: 6vw;
      margin-bottom: 100px;
    }
    .hero-subtitle{
      font-size: 3vw;
    }
    .hero-button{
      font-size: 3vw;
    }
    .hero-content{
     
      top: 35%;
    }
  }
  @media (max-width: 400px){
    .hero-title{
      font-size: 5.5vw;
      margin-bottom: 60px;
    }
    .hero-subtitle{
      font-size: 2.5vw;
    }
    .hero-button{
      font-size: 4vw;
    }
    .hero-content{
     
      top: 35%;
    }
  }
  /* @media (min-width: 1720px) {
    .hero-title{
      font-size: 86px;
    }
    .hero-button{
      font-size: 34.4px;
    }
    .hero-subtitle{
      font-size: 51.6px;
    }
  } */

  /* @media screen and (max-width: 768px) {
    .hero-title {
      font-size: 2rem;
    }
  }
  
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    .hero-title {
      font-size: 3rem;
    }
  }
  
  @media screen and (min-width: 1024px) {
    .hero-title {
      font-size: 4vw;
    }
  }
   */

  /* @media (max-width: 754px) {
   .hero-button{
    font-size: 80%;
   }
  }
  @media (max-width: 619px) {
    .hero-button{
     font-size: 70%;
    }
   }
   @media (max-width: 607px) {
    .hero-button{
     font-size: 60%;
    }
   }
  */
  /* Media query to ensure hero image takes up full width when screen is between 620px and 708px */
  /* @media (min-width: 620px) {
    .slideshow-container {
      min-width: 715px;
    }
  }
  @media (min-width: 940px) {
    .slideshow-container {
      min-width: 1092px;
    }
  }
  @media (min-width: 1260px) {
    .slideshow-container {
      min-width: 1450px;
    }
  } */
  /* @media (max-width: 900px) {
    .hero-title{
      font-size: 350%;
    }
  }
  @media (max-width: 770px) {
    .hero-title{
      font-size: 300%;
    }
  }
  @media (max-width: 620px) {
    .hero-title{
      font-size: 220%;
    }
  }
  @media (max-width: 460px) {
    .hero-title{
      font-size: 200%;
    }
  }
  @media (max-width: 430px) {
    .hero-title{
      font-size: 160%;
    }
  }
  @media (max-width: 1212px) {
    .hero-title{
      font-size: 360%;
    }
  }
  @media (max-width: 1040px) {
    .hero-title{
      font-size: 320%;
    }
  }
  @media (min-width: 1450px) {
    .hero-title{
      font-size: 500%;
    }
  } */
  /* @media (max-width: 1094px) {
    .hero-subtitle{
     font-size: 20px;
    }
   }
   @media (max-width: 924px) {
    .hero-subtitle{
     font-size: 16px;
    }
   }
   @media (max-width: 465px) {
    .hero-subtitle{
      font-size: 12px;
    }
  } */
  /* @media (max-width: 350px) {
    .hero-title{
      font-size: 140%;
    }
    .hero-button{
      font-size: 60%;
    }
    .hero-subtitle{
      font-size: 8px;
    }
  } */