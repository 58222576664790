@import url('https://fonts.googleapis.com/css2?family=Voltaire&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans&display=swap');

.footer-btn {
    padding: 12px 24px;
    background-color: #ec5d24;;
    color: black;
    font-size: 20px;
    white-space: nowrap;
    font-weight: bold;
    border: 2px solid #ec5d24;
    border-radius: 50px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    margin-bottom: 10px; /* added margin-bottom */
    margin-top: -30px;
  }
  .footer-btn:hover {
    background-color: black !important;
    color: #ec5d24 !important;
    border: none !important;
  }
  .text-yellow{
    color:#ec5d24;
    font-size: 30px;
  }
  
  .text-orange{
    color:white;
  }

.footer-links,.hours{
  font-size: 20px;
}

  .footer-icons:hover,
  .footer-links li:hover,
  .text-orange:hover {
    color: #ec5d24;
  }
  
  @media(max-width:1199px) {
    .text-yellow{
      font-size: 25px;
    }
    .footer-links,.hours{
      font-size: 18px;
    }
  }
  @media(max-width:991px) {
    .text-yellow{
      font-size: 20px;
    }
    .footer-links,.hours{
      font-size: 15px;
    }
  }
  @media(max-width:767px) {
    .text-yellow{
      font-size: 30px;
    }
    .footer-links,.hours{
      font-size: 25px;
    }
  }
  @media(max-width:767px) {
    .text-yellow{
      font-size: 8vw;
    }
    .footer-links,.hours{
      font-size: 6vw;
    }
  }
  .foot{
    font-family: 'Plus Jakarta Sans', sans-serif;
    white-space: nowrap;
    flex-direction: column;
  }
  /*  */
  
  