@import url('https://fonts.googleapis.com/css2?family=Poiret+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Voltaire&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans&display=swap');

.about-us {
    position: relative;
   
    background: url('/public/images/about.jpeg') no-repeat center center;
    background-size: cover;
    bottom: 250px;
    z-index: 1;
    margin-bottom: -350px;
    font-family: 'Plus Jakarta Sans', sans-serif;
        padding-bottom: 200px;
  }
  
  .about-us:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(236, 136, 36, 0.4);
    z-index: -1;
  }
  
  .about-us-content {
    position: relative;
    top: 75px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 50px;
    width: 100%;
  }
  
  .about-us-left,
  .about-us-right {
    border: 2px solid black;
    border-radius: 30px;
    padding: 30px;
    width: calc(50% - 20px);
    margin: 0 10px;
    background-color: white;
  }
  
  .about-us-left h2,
  .about-us-right h2 {
    font-size: 40px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .about-us-left p,
  .about-us-right p {
    font-size: 24px;
    line-height: 1.5;
    text-align: justify;
  }
  @media (max-width: 1356px) {
    .about-us-left h2,
    .about-us-right h2 {
      font-size: 35px;
    }
    
    .about-us-left p,
    .about-us-right p {
      font-size: 20px;
    }
  }
  @media (max-width: 1009px) {
    .about-us-left h2,
    .about-us-right h2 {
      font-size: 32px;
    }
    
    .about-us-left p,
    .about-us-right p {
      font-size: 17px;
    }
  }
  @media (max-width: 758px) {
    .about-us-left h2,
    .about-us-right h2 {
      font-size: 30px;
    }
    
    .about-us-left p,
    .about-us-right p {
      font-size: 13px;
    }
  }
  @media (max-width: 560px) {
    .about-us-left h2,
    .about-us-right h2 {
      font-size: 28px;
    }
    
    .about-us-left p,
    .about-us-right p {
      font-size: 12px;
    }
  }
  @media (max-width: 518px) {
    .about-us-left h2,
    .about-us-right h2 {
      font-size: 25px;
    }
    
    .about-us-left p,
    .about-us-right p {
      font-size: 11.5px;
    }
  }
  @media (max-width: 500px) {
    .about-us-left h2,
    .about-us-right h2 {
      font-size: 22px;
    }
    
    .about-us-left p,
    .about-us-right p {
      font-size: 10.75px;
    }
  }
  .about-btn {
    position: absolute;
    z-index: 1;
    top: 110%; /* updated value */
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 12px 24px;
    background-color:#ec5d24;
    color: black;
    font-size: 110%;
    font-weight: bold;
    border: 2px solid #ec5d24;
    border-radius: 50px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    margin-bottom: 50px; /* added margin-bottom */
  }
  .about-btn:hover {
    background-color: black !important;
    color: #ec5d24 !important;
    border: none !important;
  }
  